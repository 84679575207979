#modal-container {
  background-color: $bg-color-opaque;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2010;
}

.modal-card-container {
  width: 100%;
  height: 100%;
}

.modal-card {
  margin: 0 5px 0 5px;
  width: 90%;
  min-width: 300px;
  max-width: 800px;
}

.modal-card-xl {
  width: 90%;
  max-width: 900px;
}

#container-tabla-material-consumido {
  min-height: 300px;
}