#spinner-container {
  background-color: $bg-color-opaque;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2020;
}

.spinner {
  width: 100%;
  height: 100vh;
}

.spinner div {
  width: 100px;
  height: 100px;
}