$font-size-root: null;
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.15;
$font-size-sm: $font-size-base * 0.85;
$font-size-xsm: $font-size-base * 0.75;

$primary: #0c2645;
$secondary: #0494d4;
$black: #000000;
$white: #ffffff;

$header-heigth: 75px;
$footer-heigth: 50px;
$content-heigth: calc(100vh - ($header-heigth + $footer-heigth));
$sidebar-width: 300px;
// $container-fluid-max-width: 1024px;
$app-container-max-width: 1024px;

$bg-color-opaque: rgba($color: $black,
    $alpha: 0.3,
  );

$box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
  rgba(0, 0, 0, 0.22) 0px 10px 10px;