#card-login {
  width: 100vw;
  height: 100vh;
  border: none;
  border-radius: 0px;
}

#card-login .card-header {
  border-radius: 0;
  height: auto;
}

#card-login .card-header img {
  width: 150px;
}

// #login-content {
//   padding: 20px;
//   padding-bottom: calc($footer-heigth + 20px);
// }

// #login-header {
//   width: 100vw;
//   height: $header-heigth;
//   background-color: $primary;
// }

// #login-img {
//   max-width: 480px;
// }


// #login-content .card {
//   min-width: 320px;
// }

@media screen and (min-width: 480px) {
  #card-login {
    width: 450px;
    border: 1px solid #dedede;
    border-radius: 10px;
    height: 900px;
  }

  #card-login .card-header {
    border-radius: 10px 10px 0px 0px;
  }

  #card-login .card-header img {
    width: 250px;
  }
}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}