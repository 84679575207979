@media screen and (min-width:480px) {
  .img-inicio-jornada {
    width: 350px;
  }
}

@media screen and (min-width:768px) {
  .img-inicio-jornada {
    height: 100%;
    width: 100%;
  }
}