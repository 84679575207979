.sidebar-menu,
.sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu a,
.sidebar-menu a:hover,
.sidebar-menu a:before,
.sidebar-menu a:after,
.sidebar-menu a:visited {
  text-decoration: none;
  color: rgb(33, 37, 41);
  display: block;
}

.sidebar-menu .sidebar-menu-item {
  padding: 15px 10px;
  border-top: 1px solid #dedede;
  font-size: 1.2rem;
}

.sidebar-menu .sidebar-menu-item:first-child {
  border: none;
}

.sidebar-menu li:hover {
  cursor: pointer;
  background-color: #dedede;
}

.sidebar-sub-menu .sidebar-sub-menu-item {
  padding: 15px;
  padding-left: 20px;
  padding-bottom: 10px;
  background-color: #dedede;
  font-size: 1rem;
}

.hidden-sub-menu {
  display: none;
  transition: all ease 1.3s;
}

.visible-sub-menu {
  display: block;
  transition: all ease 1.3s;
}

.sidebar-menu li svg {
  margin-top: -4px;
}