.App {
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.BarcodeScanner {
  width: 450px;
  height: 450px;
}

.BarcodeScanner video {
  position: relative;
}

.BarcodeScanner .no-camera {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 4em;
}

.BarcodeScanner.acquiring .no-camera .sign {
  visibility: hidden;
}

.BarcodeData.overlay {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  color: #000;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 1.2em;
  opacity: 0.6;
}

.BarcodeData:empty {
  visibility: hidden;
}

.BarcodeData a:link,
.BarcodeData a:visited {
  text-decoration: none;
}

.ClearButton {
  position: absolute;
  bottom: 8em;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
}