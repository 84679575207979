.footer {
  background-color: $primary;
  height: $footer-heigth;
  color: $light;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: $app-container-max-width;
}

@media screen and (min-width: 480px) {
  .footer {
    font-size: $font-size-sm;
  }
}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}