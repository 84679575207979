#sidebar-container {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}

#sidebar-background {
  background-color: $bg-color-opaque;
  width: 100%;
  height: 100%;
  max-width: $app-container-max-width;
  margin: 0 auto;
}

#sidebar-header {
  height: $header-heigth;
  background-color: $primary;
  color: $light;
  padding: 0px 10px;
}

#sidebar-header img {
  width: 100px;
}

#sidebar-content {
  padding: 10px;
}

#sidebar-footer {
  padding: 0px 10px;
  height: $footer-heigth;
  background-color: $primary;
  position: fixed;
  bottom: 0;
  color: $light;
  width: 100%;
  font-size: 0.75rem;
}

.sidebar {
  position: relative;
  width: 80%;
  max-width: 800px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $light;
}

.sidebar-close-button,
.sidebar-open-button {
  font-size: 2rem;
}

.sidebar-close-button:hover,
.sidebar-open-button:hover {
  cursor: pointer;
}