.changelog {
  text-decoration: underline;
}

.changelog:hover {
  cursor: pointer;
}

.changelog-modal {
  width: 80% !important;
  max-height: 80%;
}

.changelog-modal-body {
  overflow: scroll;
}

.changelog-modal-text-block p {
  margin-top: 0;
  margin-bottom: 0;
}