#userinfo-card {
  position: absolute;
  width: 240px;
  top: $header-heigth;
  right: 0px;
  font-size: $font-size-xsm;
}

#userinfo-card .list-group-item {
  background-color: $primary;
  color: $light;
  border-bottom: 1px solid $light;
}
